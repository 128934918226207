<template>
    <MainPage></MainPage>
</template>

<script>
import MainPage from "../../elements/MainPage"

export default {
    name: "recrutements-main",
    components: {
        MainPage,
    },
}
</script>

<style scoped>

</style>
