import { render, staticRenderFns } from "./ListInscrits.vue?vue&type=template&id=2300f3bc&scoped=true&"
import script from "./ListInscrits.vue?vue&type=script&lang=js&"
export * from "./ListInscrits.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2300f3bc",
  null
  
)

export default component.exports