<template>
    <MainPage></MainPage>
</template>

<script>
    import MainPage from "../../elements/MainPage"

    export default {
        name: "inscrits-main",
        components: {
            MainPage,
        },
    }
</script>

<style scoped>

</style>
